import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import styles from "./footer.module.css";

const Footer = () => (
  <footer className={styles.mainFooter}>
    <p>
      I write about product development on{" "}
      <OutboundLink href="https://medium.com/@slwenoka">Medium</OutboundLink>. I
      post design and motion on{" "}
      <OutboundLink href="https://dribbble.com/samenoka">Dribbble</OutboundLink>
      . I have code on{" "}
      <OutboundLink href="https://github.com/slwen">GitHub</OutboundLink>. My
      working history is on{" "}
      <OutboundLink href="https://www.linkedin.com/in/slwenoka">
        LinkedIn
      </OutboundLink>
      .
    </p>
    <div>
      <span role="img" aria-label="Sparkle!">
        ✨
      </span>
    </div>
  </footer>
);

export default Footer;
