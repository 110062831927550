import React from "react";
import styles from "./gallery.module.css";
import { OutboundLink } from "gatsby-plugin-google-analytics";

import tileCanva from "../../images/thumbnail_canva_flat.jpg";
import tileBooking from "../../images/thumbnail_bookingcom_flat.jpg";
import cinemaApp from "../../images/cinema-app.png";
import invites from "../../images/invites_dribbble.mp4";
import charts from "../../images/charts-dribbble.gif";
import okrIllustration from "../../images/OKR-illustration.png";
import swipe from "../../images/swipe.gif";
import busDirections from "../../images/busDirections.gif";
import radiatingWave from "../../images/radiating-wave.gif";
import star from "../../images/star.mp4";

const mp4Props = {
  autoPlay: true,
  loop: true,
  muted: true,
  playsInline: true
};

const GalleryItem = ({ url, caption, children }) => {
  return (
    <OutboundLink href={url} className={styles.galleryItem} title={caption}>
      {children}
      <div className={styles.galleryItemCaption}>{caption}</div>
    </OutboundLink>
  );
};

const Gallery = () => (
  <div className={styles.gallery}>
    <GalleryItem
      url="https://medium.com/@slwenoka/case-study-booking-basic-e5bc7d15c56"
      caption="Case Study: Running experiments at Booking.com"
    >
      <img src={tileBooking} alt="Booking.com app" />
    </GalleryItem>
    <GalleryItem
      url="https://medium.com/@slwenoka/case-study-brainstorming-workshop-at-canva-ee9076222737"
      caption="Case Study: Facilitating workshops at Canva"
    >
      <img src={tileCanva} alt="Canva team workshop" />
    </GalleryItem>
    <GalleryItem
      url="https://dribbble.com/shots/4176033-Swipe"
      caption="Swipe interaction prototype"
    >
      <img src={swipe} alt="Swipe interaction prototype" />
    </GalleryItem>
    <GalleryItem
      url="https://dribbble.com/shots/6011029-Dynamic-Chart-Animations"
      caption="Data visualisation prototype"
    >
      <img src={charts} alt="Charts animated gif" />
    </GalleryItem>
    <GalleryItem
      url="https://dribbble.com/shots/6010921-Invitation-Delivered"
      caption="Email invite delivery prototype"
    >
      <video src={invites} {...mp4Props} />
    </GalleryItem>
    <GalleryItem
      url="https://dribbble.com/shots/5534232-Radiating-Touch"
      caption="Radiating Touch Framer X interaction"
    >
      <img src={radiatingWave} alt="Radiating Wave prototype" />
    </GalleryItem>
    <GalleryItem
      url="https://dribbble.com/shots/4129064-Cinema-app-design"
      caption="Cinema app UI design"
    >
      <img src={cinemaApp} alt="Cinema app design" />
    </GalleryItem>
    <GalleryItem
      url="https://dribbble.com/shots/4128413-Starred-Animation"
      caption="WatchOS fitness app UI animation"
    >
      <video src={star} {...mp4Props} />
    </GalleryItem>
    <GalleryItem
      url="https://dribbble.com/shots/4258383-OKR-Spot-Illustrations"
      caption="Spot illustrations for an OKR's app"
    >
      <img src={okrIllustration} alt="OKR Illustrations set" />
    </GalleryItem>
    <GalleryItem
      url="https://dribbble.com/shots/4128475-Bus-Arriving-Animation"
      caption="Animated iOS interaction animation"
    >
      <img src={busDirections} alt="Bus app prototype" />
    </GalleryItem>
  </div>
);

export default Gallery;
