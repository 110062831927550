import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Gallery from "../components/gallery";

import "typeface-roboto";

const IndexPage = () => (
  <Layout>
    <SEO title="Sam Enoka" keywords={[`gatsby`, `application`, `react`]} />
    <Gallery />
  </Layout>
);

export default IndexPage;
