import React from "react";
import ProfileImage from "../ProfileImage";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import styles from "./header.module.css";

const Header = () => (
  <header className={styles.mainHeader}>
    <div className={styles.profileImg}>
      <ProfileImage />
    </div>
    <h3>Sam Enoka</h3>
    <h1>Product Designer</h1>
    <p className={styles.profileDescription}>
      I design products from end-to-end; from collaborative whiteboard sessions,
      to creating high fidelity prototypes, to interacting with users and
      testing ideas.{" "}
      <OutboundLink href="https://www.linkedin.com/in/slwenoka">
        Contact me on LinkedIn
      </OutboundLink>{" "}
      or see my recent work below.
    </p>
  </header>
);

export default Header;
